<template>
  <div v-if="page_loading">
    <Loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <Loading :loading="loading" />
    <div v-if="order!=null">

      <!-- แสดงภาพ -->
      <v-dialog v-model="ShowPhoto" max-width="700">
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
                <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="650">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- แพลตฟอร์ม ข้อมูลสินค้า -->
      <v-card class="v-card-margin-bottom-30" elevation="1">
        <v-card-title>
          <span v-if="order.referenceNumber!=null"> แพลตฟอร์ม </span>
          <span v-else> ข้อมูลสินค้า </span>
        </v-card-title>
        <v-card-text>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> URL สำหรับลูกค้า : </v-col>
            <v-col cols="8" md="9" lg="10">
              <v-row>
                <a :href="this.urlCuntomer" target="_blank">
                  {{ this.urlCuntomer }}
                  <input type="hidden" id="urlcuntomer" style="width:100%" v-model="urlCuntomer" />
                </a>
                <v-spacer/>
                <v-btn :elevation="1" color="primary" class="pa-2" @click="CopyUrl()">
                  <v-icon size="14" class="pr-1">fa-solid fa-copy</v-icon>
                  คัดลอก URL
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ช่องทาง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <v-avatar v-if="order.channelLogo!=null" size="25px" tile>
                <v-img v-if="order.channelLogo!=''&&order.channelLogo!=null" size="25px" :src="order.channelLogo"/>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> เปิดออเดอร์ : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if=" order.createdByStaffShopName != null">{{  order.createdByStaffShopName }}</span>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> พนักงานขาย : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if=" order.salemanName != null">{{  order.salemanName }}</span>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3" v-if="order.referenceNumber!=null">
            <v-col cols="4" md="3" lg="2"> เลขใบสั่งซื้อ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ order.referenceNumber }}
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-4">
            <v-col cols="4" md="3" lg="2"> ที่อยู่ผู้รับ : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.tempAddress!=null && order.tempAddress!=''">{{ order.tempAddress }}</span>
              <span v-else-if="order.receiverName==null || order.receiverName==''">  </span>
              <span v-else>{{ order.receiverName+' '+order.receiverPhone+' '+order.receiverAddress+' '+order.receiverSubDistrict+' อำเภอ'+order.receiverDistrict+' จังหวัด'+order.receiverProvince+' '+order.receiverPostcode }}</span>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-2">
            <v-col cols="4" md="3" lg="2"> รูปแบบพัสดุ : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.isCOD">
                <v-chip dark class="mr-1" color="#EA5455"> COD </v-chip>
                <span v-if="order.codAmount!=null"> ยอด {{ formatMoneyIsDecimal(order.codAmount) }} บาท </span>
              </span>
              <span v-else><v-chip color="primary" outlined> ธรรมดา </v-chip></span>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> หมายเหตุ : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.remark!=null" class="color-red"> {{ order.remark }} </span>
            </v-col>
          </v-row>

          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> สินค้า : </v-col>
            <v-col cols="8" md="9" lg="10" v-if="order.referenceNumber!=null || orderItemInvoice_count>0" class="hidden-xs-only">
              <v-data-table class="packhai-border-table"
                :headers="headerproduct"
                :items="dataproduct"
                :items-per-page="9999999999"
                :mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:item.photoLink="{ item }">
                  <a v-if="item.imgUrl!=null && item.imgUrl!='' && orderItemCount>0" icon @click="OpenPhoto(item.imgUrl)">
                    <img :src="item.imgUrl" class="picsize-50 mt-1">
                  </a>
                  <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                    <img :src="item.photoLink" class="picsize-50 mt-1">
                  </a>
                </template>
                <template v-slot:item.name="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.name }}
                    <span v-if="orderItemInvoice_count>0" class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                    <br/>
                    ไม่พบสินค้าในระบบ
                  </div>
                  <div v-else>
                    {{ item.name }}
                    <span v-if="orderItemInvoice_count>0" class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                  </div>
                </template>
                <template v-slot:item.sku="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.sku }}
                  </div>
                  <div v-else>
                    {{ item.sku }}
                  </div>
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>
                <template v-slot:item.unitPrice="{ item }">
                  {{ formatMoneyIsDecimal(item.unitPrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ formatMoneyIsDecimal(item.discount) }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="8" md="9" lg="10" v-else>
              <v-data-table class="packhai-border-table"
                :headers="headerpack"
                :items="datapack"
                :items-per-page="9999999999"
                :mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:item.productName="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.productName }}
                    <span class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                    <br/>
                    ไม่พบสินค้าในระบบ
                  </div>
                  <div v-else>
                    {{ item.productName }}
                    <span class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                  </div>
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a v-if="item.imgUrl!=null && item.imgUrl!='' && orderItemCount>0" icon @click="OpenPhoto(item.imgUrl)">
                    <img :src="item.imgUrl" class="picsize-50 mt-1">
                  </a>
                  <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                    <img :src="item.photoLink" class="picsize-50 mt-1">
                  </a>
                </template>
                <template v-slot:item.sku="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.sku }}
                  </div>
                  <div v-else>
                    {{ item.sku }}
                  </div>
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>
                <template v-slot:item.unitPrice="{ item }">
                  {{ formatMoneyIsDecimal(item.unitPrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ formatMoneyIsDecimal(item.discount) }}
                </template>
                <template v-slot:item.totalPrice="{ item }">
                  {{ formatMoneyIsDecimal((item.quantity * item.unitPrice)-item.discount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row class="pb-3 hidden-sm-and-up">
            <v-col cols="12" v-if="order.referenceNumber!=null || orderItemInvoice_count>0">
              <v-data-table class="packhai-border-table"
                :headers="headerproduct"
                :items="dataproduct"
                :items-per-page="9999999999"
                :mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:item.photoLink="{ item }">
                  <a v-if="item.imgUrl!=null && item.imgUrl!='' && orderItemCount>0" icon @click="OpenPhoto(item.imgUrl)">
                    <img :src="item.imgUrl" class="picsize-50 mt-1">
                  </a>
                  <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                    <img :src="item.photoLink" class="picsize-50 mt-1">
                  </a>
                </template>
                <template v-slot:item.name="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.name }}
                    <span v-if="orderItemInvoice_count>0" class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                    <br/>
                    ไม่พบสินค้าในระบบ
                  </div>
                  <div v-else>
                    {{ item.name }}
                    <span v-if="orderItemInvoice_count>0" class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                  </div>
                </template>
                <template v-slot:item.sku="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.sku }}
                  </div>
                  <div v-else>
                    {{ item.sku }}
                  </div>
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>
                <template v-slot:item.unitPrice="{ item }">
                  {{ formatMoneyIsDecimal(item.unitPrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ formatMoneyIsDecimal(item.discount) }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-data-table class="packhai-border-table"
                :headers="headerpack"
                :items="datapack"
                :items-per-page="9999999999"
                :mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:item.productName="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.productName }}
                    <span class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                    <br/>
                    ไม่พบสินค้าในระบบ
                  </div>
                  <div v-else>
                    {{ item.productName }}
                    <span class="fn-11 color-b3">
                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                    </span>
                  </div>
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a v-if="item.imgUrl!=null && item.imgUrl!='' && orderItemCount>0" icon @click="OpenPhoto(item.imgUrl)">
                    <img :src="item.imgUrl" class="picsize-50 mt-1">
                  </a>
                  <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                    <img :src="item.photoLink" class="picsize-50 mt-1">
                  </a>
                </template>
                <template v-slot:item.sku="{ item }">
                  <div v-if="item.notSku" class="color-red">
                    {{ item.sku }}
                  </div>
                  <div v-else>
                    {{ item.sku }}
                  </div>
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>
                <template v-slot:item.unitPrice="{ item }">
                  {{ formatMoneyIsDecimal(item.unitPrice) }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ formatMoneyIsDecimal(item.discount) }}
                </template>
                <template v-slot:item.totalPrice="{ item }">
                  {{ formatMoneyIsDecimal((item.quantity * item.unitPrice)-item.discount) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6"/>
            <v-col cols="12" md="6">
              <v-row class="pt-3 pr-10">
                <v-col cols="6" align="end" class="pb-2">
                  <span v-if="order.vatType == 2"> ราคารวมภาษี : </span>
                  <span v-else-if="order.vatType == 3"> ราคายังไม่รวมภาษี : </span>
                  <span v-else> ราคารวม : </span>
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  {{ formatMoneyIsDecimal(order.totalPrice) }}
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  ค่าส่ง :
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  {{ formatMoneyIsDecimal(order.deliveryCost) }}
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  ส่วนลด :
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  {{ formatMoneyIsDecimal(order.orderDiscount) }}
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  <span v-if="order.vatType == 1"> ภาษีมูลค่าเพิ่ม(No VAT) : </span>
                  <span v-else-if="order.vatType == 2"> ภาษีมูลค่าเพิ่ม(VAT Included) : </span>
                  <span v-else-if="order.vatType == 3"> ภาษีมูลค่าเพิ่ม(VAT Excluded) : </span>
                  <span v-else> ภาษีมูลค่าเพิ่ม : </span>
                </v-col>
                <v-col cols="6" align="end" class="pb-2">
                  {{ formatMoneyIsDecimal(order.vatAmount) }}
                </v-col>
                <v-col cols="6" align="end">
                  รวมทั้งหมด :
                </v-col>
                <v-col cols="6" align="end">
                  {{ formatMoneyIsDecimal((order.orderTotalAmount + order.deliveryCost) - order.orderDiscount) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- สินค้าต้องแพ็ค -->
      <v-card class="v-card-margin-bottom-30" elevation="1">
        <v-card-title>
          <span v-if="order.shippingStatusID<=40"> สินค้าต้องแพ็ค </span>
          <span v-else> รายละเอียดสินค้า </span>
        </v-card-title>

        <v-card-text class="pl-10 hidden-xs-only">
          <v-data-table class="packhai-border-table"
            :headers="headerpack"
            :items="datapack"
            :items-per-page="9999999999"
            hide-default-footer
          >
            <template v-slot:item.productName="{ item }">
              {{ item.productName }}
              <span class="fn-11 color-b3">
                {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize-50 mt-1">
              </a>
            </template>
            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
            <template v-slot:item.unitPrice="{ item }">
              {{ formatMoneyIsDecimal(item.unitPrice) }}
            </template>
            <template v-slot:item.discount="{ item }">
              {{ formatMoneyIsDecimal(item.discount) }}
            </template>
            <template v-slot:item.totalPrice="{ item }">
              {{ formatMoneyIsDecimal((item.quantity * item.unitPrice)-item.discount) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-text class="hidden-sm-and-up">
          <v-data-iterator
            v-if="datapack.length != 0"
            :items="datapack"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item item-stert">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0" >

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                ชื่อ
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.productName != null">
                                        : {{ item.productName }}
                                        <span class="fn-11 color-b3">
                                          {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                                        </span>
                                      </span>
                                    </div>
                                  </template>
                                  <span v-if=" item.productName != null">
                                    {{ item.productName }}
                                    <span class="fn-11 color-b3">
                                      {{ (item.prop1Description !=null ? item.prop1Description : "") + (item.prop2Description != null ? " , "+item.prop2Description : "") }}
                                    </span>
                                  </span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  Barcode
                                </div>
                                <div class="cut-text">
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  SKU
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  จำนวน
                                </div>
                                <div class="cut-text">
                                  : {{ item.quantity }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  ราคา/หน่อย
                                </div>
                                <div class="cut-text">
                                  : {{ item.unitPrice }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  ส่วนลด
                                </div>
                                <div class="cut-text">
                                  : {{ item.discount }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  ราคารวม
                                </div>
                                <div class="cut-text">
                                  : <span v-if="item.quantity != null">{{ formatMoney(item.unitPrice * item.quantity - item.discount) }}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
      </v-card>

      <!-- รายการแจ้งโอนเงิน -->
      <v-card v-if="!order.isCOD" class="v-card-margin-bottom-30" elevation="1">
        <v-card-title>
          รายการแจ้งโอนเงิน
        </v-card-title>
        <v-card-text class="pl-10">
          <v-data-table class="packhai-border-table"
            :headers="headerpay"
            :items="datapay"
            :items-per-page="9999999999"
            :mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize-50 mt-1">
              </a>
            </template>
            <template v-slot:item.informDatetime="{ item }">
              {{ formatDatetime(item.informDatetime) }}
            </template>
            <template v-slot:item.createdDatetime="{ item }">
              {{ formatDatetime(item.createdDatetime) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- สถานะออเดอร์ -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          สถานะออเดอร์
        </v-card-title>
        <v-card-text class="pl-10">
          <v-row class="pb-3" v-for="(item, index) in orderStatusHistory" :key="index">
            <v-col cols="4" md="3" lg="2"> {{ item.updateDateTime }} </v-col>
            <v-col cols="8" md="9" lg="10"> {{ item.description }} <span v-if="item.packName!=null"> ({{ item.packName }}) </span> </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- แพ็คสินค้า -->
      <v-card class="v-card-margin-bottom-30" elevation="1">
        <v-card-title>
          แพ็คสินค้า
        </v-card-title>
        <v-card-text class="pl-10">
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> น้ำหนัก : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.weight!=null"> {{ formatMoneyIsDecimal(order.weight)+' กิโลกรัม' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> กว้าง x ยาว x สูง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.cubic1!=null && order.cubic2!=null && order.cubic3!=null">
                {{ order.cubic1+' x '+order.cubic2+' x '+order.cubic3 }}
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่ากล่อง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.boxPrice!=null"> {{ formatMoneyIsDecimal(order.boxPrice)+' บาท' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าแพ็ค : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.packPrice!=null"> {{ formatMoneyIsDecimal(order.packPrice)+' บาท' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าขนส่ง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.deliveryPrice!=null"> {{ formatMoneyIsDecimal(order.deliveryPrice)+' บาท' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> % COD : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.codPercent!=null"> {{ formatMoneyIsDecimal(order.codPercent)+' %' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าCOD : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.codcharged!=null"> {{ formatMoneyIsDecimal(order.codcharged)+' บาท' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> พื้นที่ห่างไกล : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.remotePrice!=null"> {{ formatMoneyIsDecimal(order.remotePrice)+' บาท' }} </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ส่วนลด : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.discount!=null"> {{ formatMoneyIsDecimal(order.discount)+' บาท' }} </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- พนักงานแพ็ค -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          พนักงานแพ็ค
        </v-card-title>
        <v-card-text class="pl-10">
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> เริ่มแพ็ค : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ formatDatetime(order.startPackingDate) }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> แพ็คเสร็จ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ formatDatetime(order.packedDate) }}
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> แพ็คโดย : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ order.packedByStaffBranchName }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- สถานะพัสดุ -->
      <!-- <v-card class="v-card-margin-bottom-30" elevation="1">
        <v-card-title>
          สถานะพัสดุ
        </v-card-title>
        <v-card-text class="pl-10">
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ขนส่ง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <v-img v-if="order.expressCompanyLogo!=''&&order.expressCompanyLogo!=null" max-width="10vh" :src="order.expressCompanyLogo"/>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> TrackingNo : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="order.trackingNo!=null">{{ order.trackingNo }}</span>
              <div class="pb-2" />
              <div v-if="statusExpress != null">
                <div v-if="statusExpress.status!=null && statusExpress.status.length>0">
                  <div v-for="(item, index) in statusExpress.status" :key="index">
                    <span> {{formatDatetime(item.date) +" "+ item.description}} </span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> สถานะ : </v-col>
            <v-col cols="8" md="9" lg="10">
              <div>
                <span v-if="order.parcelStatusID == 0" style="color: purple;">{{ order.parcelStatusName }} </span>
                <span v-else-if="order.parcelStatusID == 1" style="color: orange;">{{ order.parcelStatusName }} </span>
                <span v-else-if="order.parcelStatusID == 2" style="color: red;">{{ order.parcelStatusName }} </span>
                <span v-else-if="order.parcelStatusID == 3" style="color: green;">{{ order.parcelStatusName }} </span>
                <span v-else>{{ order.parcelStatusName }} </span>

                <span v-if="order.expressCompanyID!=1&&order.expressCompanyID!=7">
                  (<a @click="CheckShippingStatus(order.expressCompanyID,order.trackingNo)"> กดเพื่อเช็คสถานะ </a>)
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->

      <!-- รูปแพ็คสินค้า -->
      <v-card elevation="1" class="v-card-margin-bottom-30" v-if="order.shippingStatusID >= 40">
        <v-card-title>
          รูปแพ็คสินค้า
        </v-card-title>
        <v-card-text class="pl-10">
          <v-row dense>
            <v-col :cols="6" :md="3" :xl="2" v-for="(item, index) in orderPack" :key="index" class="pa-1">
              <a v-if="item.pic!=null && item.pic!=''" icon @click="OpenPhoto(item.pic)">
                <v-img :src="item.pic" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"/>
              </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { customerWebsite, orderService_dotnet } from '@/website/global'
  import { AlertError, AlertSuccess } from '@/website/global_alert'
  import { formatMoney, formatMoneyIsDecimal, formatDatetime, GenPA } from '@/website/global_function'
  export default {
    props: [
      'orderId', // ส่ง int หรือ null
      'orderToken', // ส่ง int หรือ null
    ],
    components: {
      Loading,
    },
    data: () => ({
      // loading
      page_loading: true,
      loading: false,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      order: null,
      orderItemCount: 0,
      orderItemInvoice_count: 0,

      // ข้อมูลสินค้า
      headerproduct: [
        { align: 'center', text: 'ภาพ', value: 'photoLink', sortable: false, width: '100px'},
        { align: 'left', text: 'ชื่อ', value: 'name', sortable: false},
        { align: 'center', text: 'sku', value: 'sku', sortable: false, width: '140px'},
        { align: 'center', text: 'จำนวน', value: 'quantity', sortable: false, width: '90px'},
        { align: 'right', text: 'ราคา/หน่อย', value: 'unitPrice', sortable: false, width: '110px'},
        { align: 'right', text: 'ส่วนลด', value: 'discount', sortable: false, width: '90px'},
      ],
      dataproduct: [],

      // สินค้าต้องแพ็ค
      headerpack: [
        { align: 'center', text: 'ภาพ', value: 'photoLink', sortable: false, width: '100px'},
        { align: 'left', text: 'ชื่อ', value: 'productName', sortable: false},
        { align: 'center', text: 'บาร์โค้ด', value: 'barcode', sortable: false, width: '140px'},
        { align: 'center', text: 'sku', value: 'sku', sortable: false, width: '140px'},
        { align: 'center', text: 'จำนวน', value: 'quantity', sortable: false, width: '90px'},
        { align: 'right', text: 'ราคา/หน่อย', value: 'unitPrice', sortable: false, width: '110px'},
        { align: 'right', text: 'ส่วนลด', value: 'discount', sortable: false, width: '90px'},
        { align: 'right', text: 'ราคารวม', value: 'totalPrice', sortable: false, width: '110px'},
      ],
      datapack: [],

      // สินค้าต้องแพ็ค
      headerpay: [
        { align: 'center', text: 'ภาพ', value: 'photoLink', sortable: false, width: '100px'},
        { align: 'left', text: 'ธนาคาร', value: 'bankAccountText', sortable: false},
        { align: 'right', text: 'จำนวน', value: 'amount', sortable: false, width: '120px'},
        { align: 'center', text: 'วันที่โอน', value: 'informDatetime', sortable: false, width: '150px'},
        { align: 'center', text: 'วันที่แจ้งโอน', value: 'createdDatetime', sortable: false, width: '150px'},
      ],
      datapay: [],

      urlCuntomer: null,
      orderStatusHistory: [],
      orderPack: [],
      statusExpress: null,
    }),
    async created() {
      document.title = "ออเดอร์ : "
      await this.Load_Data()
      document.title = "ออเดอร์ : "+ GenPA(this.order.id)
      this.page_loading = false
    },
    methods: {
      async Load_Data() {
        let response = await axios.post(orderService_dotnet+'Order/show-order-detail', {
          'orderId': this.orderId,
          'orderToken': this.orderToken,
        }, {headers: header_token})
        if (response.status==200){
          this.order = response.data.vOrderMaster
          this.orderStatusHistory = response.data.orderStatusHistory
          this.orderPack = response.data.orderPack
          this.datapay = response.data.orderPay
          this.statusExpress = response.data.statusExpress
          this.urlCuntomer = customerWebsite+'purchase-order='+this.order.token

          this.orderItemCount = response.data.vOrderItem.length
          if (this.orderItemCount >0){
            this.datapack = response.data.vOrderItem
          } else {
            this.datapack = response.data.orderItemTemp
            for (var k in this.datapack){
              this.datapack[k].photoLink = this.datapack[k].imgUrl
              this.datapack[k].productName = this.datapack[k].name
            }
          }

          if (this.order.referenceNumber!=null){
            this.dataproduct = response.data.orderItemTemp
          } else {
            var dataproduct = []
            var orderItemInvoice = response.data.orderItemInvoice
            this.orderItemInvoice_count = orderItemInvoice.length
            var vStockMaster = response.data.vStockMaster
            for (var i in orderItemInvoice){
              for (var j in vStockMaster){
                if (orderItemInvoice[i].productMasterID == vStockMaster[j].productMasterId){
                  var data = {
                    "name": vStockMaster[j].name,
                    "sku": vStockMaster[j].sku,
                    "photoLink": vStockMaster[j].photoLink,
                    "prop1": vStockMaster[j].prop1,
                    "prop1Description": vStockMaster[j].prop1Description,
                    "prop2": vStockMaster[j].prop2,
                    "prop2Description": vStockMaster[j].prop2Description,
                    "quantity": orderItemInvoice[i].quantity,
                    "unitPrice": orderItemInvoice[i].unitPrice,
                    "discount": orderItemInvoice[i].discount,
                    "notSku": orderItemInvoice[i].notSku,
                  }
                  dataproduct.push(data)
                }
              }
            }
            this.dataproduct = dataproduct
          }
        } else {
          this.AlertError()
        }
      },
      CopyUrl() {
        let TokenCodeToCopy = document.querySelector('#urlcuntomer')
        TokenCodeToCopy.setAttribute('type', 'text')
        TokenCodeToCopy.select()

        var successful = document.execCommand('copy');
        if (successful)
          this.AlertSuccess()

        else
          this.AlertError()

        TokenCodeToCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      CheckShippingStatus (expressCompanyId,trackingNo) {
        if(expressCompanyId == 2){
          window.open('https://th.kerryexpress.com/en/track/?track='+trackingNo)
        }else if (expressCompanyId == 3) {
          window.open('https://track.thailandpost.co.th/')
        }else if (expressCompanyId == 4) {
          window.open('https://track.thailandpost.co.th/')
        }else if (expressCompanyId == 5) {
          window.open('https://track.thailandpost.co.th/')
        }else if (expressCompanyId == 6) {
          window.open('https://www.nimexpress.com/web/p/tracking')
        }else if (expressCompanyId == 10) {
          window.open('https://www.scgexpress.co.th/tracking')
        }else if (expressCompanyId == 11) {
          window.open('https://www.jtexpress.co.th/index/query/gzquery.html')
        }else if (expressCompanyId == 12) {
          window.open('https://www.ninjavan.co/th-th/tracking')
        }else if (expressCompanyId == 13) {
          window.open('https://www.dhl.com/th-th/home/tracking.html')
        }
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      formatMoney,
      formatMoneyIsDecimal,
      formatDatetime,
      GenPA,
      AlertError,
      AlertSuccess,
    }
  }
</script>
